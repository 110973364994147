html {
    width: 100%;
    height: 100vh;

}
#root {
    position: relative;
    width: 100%;
    height: 100vh;
}
